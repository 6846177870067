import { useState, useEffect, useCallback } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import axios from 'axios';

function App() {
  const [spec, setSpec] = useState<any>({});

  const getSpec = useCallback(async () => {
    try {
      const specResponse = await axios.get('onramp-api.yaml');
      setSpec(specResponse.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getSpec();
  }, [getSpec]);

  return (
    <div>
      <div
        style={{
          width: '90%',
          margin: '50px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <a href="/onramp-api.pdf" target="_blank">
          Download
        </a>
        <div
          style={{
            width: '30px',
          }}
        />
        <a href="/onramp-products.pdf" target="_blank">
          Product Codes
        </a>
      </div>
      <SwaggerUI spec={spec} />
    </div>
  );
}

export default App;
